import * as React from 'react'
import { useConfig } from '../../hooks/useConfig'

export const Header = () => {
   const { trainer } = useConfig()
   return (<header className="header">
     {trainer?.logo
        ? <>
         <img src={trainer.logo} alt={trainer.name} className="header__logo" />
         <div className="header__coopText">Zertifizierter Partner des UNIQUE - Institut für Persönlichkeit</div>
       </>
        : <img src="/static/questionnaire/images/unique-logo.png" alt="UNIQUE - Institut für Persönlichkeit" className="header__logo" />}
   </header>)
}
