import * as React from 'react'
import { IAPITrainerResponse } from '../lib/APIService'

export interface IConfig {
  trainer: IAPITrainerResponse
}

export const ConfigContext = React.createContext(null)

export const Provider = ConfigContext.Provider

export function useConfig(mapStateToProps?: Function): IConfig {
  const config = React.useContext(ConfigContext)

  if (typeof mapStateToProps !== 'undefined') {
    return mapStateToProps(config)
  }

  return config
}
