import * as React from 'react'
import { useEffect, useRef, useState } from 'react'
import { Radar } from '../../shared/Radar'
import classnames from 'classnames'
import { AddIcon } from '../../icons/AddIcon'
import { TValue } from './ResultScreen'

const OUTER_CHART_CIRCLE_RATIO = 0.715
const SEGMENT_ANGLES = [18, 53, 90.5, 125, 163.5, 197.5, 235.3, 269.8, 305, 341]

interface IProps {
  values: TValue[]
  onAddClick?: () => any
}

export const RadarChart = ({ values, onAddClick }: IProps) => {
  const containerRef = useRef<HTMLElement>()
  const [containerDimensions, setContainerDimensions] = useState<[number, number]>([0, 0])
  const viewBox = `0 0 ${containerDimensions[0]} ${containerDimensions[1]}`

  useEffect(() => {
    const container = containerRef.current
    if (!container) return

    const onResize = () => setContainerDimensions([container.offsetWidth, container.offsetHeight])
    onResize()

    window.addEventListener('resize', onResize)
    return () => window.removeEventListener('resize', onResize)
  }, [containerRef])

  const getPathPositions = (values) => {
    const originalDimensions = [2200, 2010]
    const scale = containerDimensions[0] / originalDimensions[0]
    const center = [1104 * scale, 959 * scale]
    const radius = (containerDimensions[1] * OUTER_CHART_CIRCLE_RATIO) / 2

    return values.map((value, index) => {
      const angle = -90 + SEGMENT_ANGLES[index]
      const distanceFromCenter = radius * 0.1 + (value / 100) * (radius)
      const x = distanceFromCenter * Math.cos((angle * Math.PI) / 180) + center[0]
      const y = distanceFromCenter * Math.sin((angle * Math.PI) / 180) + center[1]

      return [x, y]
    })
  }

  const renderPaths = () => {
    const paths = []
    values.forEach(({ values }, chartIndex) => {
      const color = 'var(--chartValueColor' + (chartIndex + 1) + ')'
      let d = ''
      getPathPositions(values).forEach(([x, y], index) => {
        paths.push(<circle cx={x} cy={y} r={5} style={{ fill: color }} key={`circle-${chartIndex}-${index}`} />)

        if (index === 0) {
          d += ' M'
        } else {
          d += ' L'
        }
        d += `${x} ${y}`
      })
      d += ' Z'
      paths.push(<path d={d} strokeWidth={3} style={{ stroke: color }} fill="transparent" key={chartIndex} />)
    })
    return paths
  }

  const [hoveredIndex, hoverIndex] = useState(null)
  const onControlMouseEnter = (resultIndex, index) => {
    hoverIndex([resultIndex, index])
  }

  const onControlMouseLeave = () => {
    hoverIndex(null)
  }

  const renderControls = () => {
    const controls = []
    values.forEach(({ values }, resultIndex) => {
      const pathPositions = getPathPositions(values)
      pathPositions.forEach(([x, y], index) => {
        controls.push(<div
          className="radar__control"
          style={{ top: y, left: x }}
          onMouseEnter={onControlMouseEnter.bind(null, resultIndex, index)}
          onMouseLeave={onControlMouseLeave.bind(null)} />)
      })
    })
    return controls
  }

  const [ownValues] = values
  const reversedValues = values.slice(0).reverse()

  let hoveredPathPositions = null
  let hoveredResultValues = null
  if (hoveredIndex) {
    hoveredResultValues = values[hoveredIndex[0]]
    hoveredPathPositions = getPathPositions(hoveredResultValues.values)
  }
  return (
    <div className="radar">
      <div className="radar__top" ref={containerRef}>
        <Radar className="radar__background" />
        <svg className="radar__chart" viewBox={viewBox}>{renderPaths()}</svg>
        <div className="radar__controls">{renderControls()}</div>
        {hoveredIndex !== null && <div
          className="radar__tooltip"
          style={{
            top: hoveredPathPositions[hoveredIndex[1]][1],
            left: hoveredPathPositions[hoveredIndex[1]][0]
          }}>
          {hoveredIndex[0] === 0 ? 'Du befindest dich' : `${hoveredResultValues.name} befindet sich`} im {Math.round(hoveredResultValues.values[hoveredIndex[1]])}. Perzentil
        </div>}
      </div>
      <div className="radar__bottom">
        <ul className="radarKey">
          {reversedValues.map(({ name }, index) => {
            const color = 'var(--chartValueColor' + (reversedValues.length - index) + ')'
            return (
              <li
                className={classnames('radarKey__item', { 'radarKey__item--self': index === reversedValues.length - 1 })}
                key={index}
              >
                <div className="radarKey__itemSquare" style={{ backgroundColor: color }} />
                <div className={classnames('radarKey__itemLabel', { 'radarKey__itemLabel--you': index === reversedValues.length - 1 })} style={{ color }}>
                  {name}
                </div>
              </li>
            )
          })}
        </ul>

        <div className="radar__addButton" onClick={onAddClick}>
          <AddIcon />
        </div>
      </div>
    </div>
  )
}
