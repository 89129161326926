import * as React from 'react'
import * as ReactDOM from 'react-dom'
import { MainComponent } from './components/MainComponent'

class Application {
  private _container: HTMLElement
  constructor(container: HTMLElement) {
    this._container = container

    ReactDOM.render(React.createElement(MainComponent), this._container)
  }
}

window.addEventListener('load', () => {
  const container = document.querySelector<HTMLElement>('.js-app')
  ;(window as any).app = new Application(container)
})
