import * as React from 'react'
import { useState } from 'react'
import { useConfig } from '../../../hooks/useConfig'

export interface IRegisterFormData {
  trainer?: number
  firstName: string
  lastName: string
  code: string
  mail: string
  consent: boolean
  privacyConsent: boolean
}

interface IProps {
  onSubmit?: (data: IRegisterFormData) => any
  initialCode?: string
}

export const RegisterForm = ({ onSubmit, initialCode = '' }: IProps) => {
  const { trainer } = useConfig()
  const [firstName, setFirstName] = useState('')
  const [lastName, setLastName] = useState('')
  const [mail, setMail] = useState('')
  const [consent, setConsent] = useState(false)
  const [privacyConsent, setPrivacyConsent] = useState(false)
  const [code, setCode] = useState(initialCode)

  const handleSubmit = (e) => {
    e.preventDefault()
    onSubmit?.({ trainer: trainer?.id, firstName, lastName, mail, code, consent, privacyConsent })
  }

  return (<form onSubmit={handleSubmit} action="" className="signupForm">
    <div className="formRow">
      <input type="text" name="first_name" className="textInput textInput--full" placeholder={trainer ? "Vorname" : "Dein Vorname"} onChange={e => setFirstName(e.target.value)} value={firstName} required />
    </div>
    <div className="formRow">
      <input type="text" name="last_name" className="textInput textInput--full" placeholder={trainer ? "Nachname" : "Dein Nachname"} onChange={e => setLastName(e.target.value)} value={lastName} required />
    </div>
    {!trainer && <div className="formRow">
      <input type="email" name="mail" className="textInput textInput--full" placeholder="Deine E-Mail-Adresse" onChange={e => setMail(e.target.value)} value={mail} required />
    </div>}
    <div className="formRow">
      <input type="text" name="code" className="textInput textInput--full textInput--upcase" placeholder={trainer ? "Test-Code" : "Dein Test-Code"} onChange={e => setCode(e.target.value.toLowerCase())} value={code} required maxLength={7} />
    </div>
    <div className="formRow formRow--narrow formRow--moreSpacing">
      <input type="checkbox" name="privacy_consent" className="checkbox" onChange={e => setPrivacyConsent(e.target.checked)} id="privacy_consent" required />
      <label htmlFor="privacy_consent">Ich habe die <a href="https://unique-institut.com/datenschutz/" target="_blank">Datenschutzerklärung</a> gelesen und stimme dieser zu.</label>
    </div>
    {!trainer && <div className="formRow formRow--narrow formRow--moreSpacing">
      <input type="checkbox" name="consent" className="checkbox" onChange={e => setConsent(e.target.checked)} id="consent" />
      <label htmlFor="consent">Ja, ich möchte weitere Informationen und Angebote erhalten.</label>
    </div>}

    <button className="splash__button">Test starten</button>
  </form>)
}
