import * as React from 'react'
import { useImperativeHandle, useState } from 'react'
import { SegmentDetail } from './SegmentDetail'
import { IAPIResultResponse } from '../../../lib/APIService'
import _ from 'lodash'
import { OffenheitIcon } from '../../icons/segments/OffenheitIcon'
import { ExtraversionIcon } from '../../icons/segments/ExtraversionIcon'
import { NeurotizismusIcon } from '../../icons/segments/NeurotizismusIcon'
import { VertraeglichkeitIcon } from '../../icons/segments/VertraeglichkeitIcon'
import { GewissenhaftigkeitIcon } from '../../icons/segments/GewissenhaftigkeitIcon'
import classnames from 'classnames'

const SEGMENT_ICONS = [
  OffenheitIcon,
  ExtraversionIcon,
  NeurotizismusIcon,
  VertraeglichkeitIcon,
  GewissenhaftigkeitIcon
]
const SEGMENTS = ['Offenheit', 'Extraversion', 'Neurotizismus', 'Verträglichkeit', 'Gewissenhaftigkeit']
const SUBSEGMENTS = [
  ['Erleben', 'Intellekt'],
  ['Enthusiasmus', 'Dominanz'],
  ['Rückzug', 'Volatilität'],
  ['Empathie', 'Politeness'],
  ['Fleiss', 'Ordnung'],
]

interface IProps {
  result: IAPIResultResponse
}

export const SegmentTexts = React.forwardRef(({ result }: IProps, ref) => {
  const [segmentIndex, setSegmentIndex] = useState(0)
  const currentSegment = SEGMENTS[segmentIndex]
  const currentSubSegments = SUBSEGMENTS[segmentIndex]
  const currentSegmentId = _.deburr(currentSegment).toLowerCase()
  const currentSubSegmentIds = currentSubSegments.map((s) => _.deburr(s).toLowerCase())

  useImperativeHandle(ref, () => ({
    switchToNext() {
      setSegmentIndex((segmentIndex + 1) % SEGMENTS.length)
    },
    switchToPrevious() {
      setSegmentIndex((segmentIndex + SEGMENTS.length - 1) % SEGMENTS.length)
    },
  }))

  const currentSubSegmentsObject = result[currentSegmentId].facetten.reduce((obj, item) => {
    return {
      ...obj,
      [Object.keys(item)[0]]: item[Object.keys(item)[0]],
    }
  })

  return (
    <>
      <ul className="segmentControl">
        {SEGMENTS.map((segment, index) => {
          const SegmentIconComponent = SEGMENT_ICONS[index]
          return (
            <li className={classnames('segmentControl__item', { 'is-active': index === segmentIndex })} onClick={() => setSegmentIndex(index)} key={index}>
              <div className="segmentControl__icon">
                <SegmentIconComponent/>
              </div>
              <div className="segmentControl__itemLabel">{segment}</div>
            </li>
          )
        })}
      </ul>

      <SegmentDetail
        headline={currentSegment}
        value={result[currentSegmentId].percentageRank}
        description={`Setzt sich aus den Teilaspekten ${currentSubSegments.join(' und ')} zusammen.`}
        text={result[currentSegmentId].text.resultText}
        strength={result[currentSegmentId].text.strength}
      />
      <SegmentDetail
        subline="Teilaspekt"
        headline={currentSubSegments[0]}
        value={currentSubSegmentsObject[currentSubSegmentIds[0]].percentageRank}
        text={currentSubSegmentsObject[currentSubSegmentIds[0]].text.resultText}
        strength={currentSubSegmentsObject[currentSubSegmentIds[0]].text.strength}
      />
      <SegmentDetail
        subline="Teilaspekt"
        headline={currentSubSegments[1]}
        value={currentSubSegmentsObject[currentSubSegmentIds[1]].percentageRank}
        text={currentSubSegmentsObject[currentSubSegmentIds[1]].text.resultText}
        strength={currentSubSegmentsObject[currentSubSegmentIds[1]].text.strength}
      />
    </>
  )
})
