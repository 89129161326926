import * as React from 'react'
import { PersonIcon } from '../../icons/PersonIcon'

export const SegmentPercentileDisplay = ({ value, label }) => {
  return (
    <div className="segmentPercentileDisplay">
      <div className="segmentPercentileDisplay__label segmentPercentileDisplay__label--left">0</div>
      <div className="segmentPercentileDisplay__line">
        <div className="segmentPercentileDisplay__indicator" style={{ left: `${value}%` }}>
          <div className="segmentPercentileDisplay__topLabel">{label}</div>
          <div className="segmentPercentileDisplay__icon">
            <PersonIcon />
          </div>
          <div className="segmentPercentileDisplay__bottomLabel">{value}. Perzentil</div>
        </div>
      </div>
      <div className="segmentPercentileDisplay__label segmentPercentileDisplay__label--right">100</div>
    </div>
  )
}
