import { Utils } from './Utils'
import { IRegisterFormData } from '../components/screens/splash/RegisterForm'

export interface IAPITextData {
  id: number
  category: string
  icon: string
  lower_border: number
  upper_border: number
  result_text: string
  strength: string
}

export interface IAPISubSegmentData {
  ordnung?: IAPIResultResponseSegment
  fleiß?: IAPIResultResponseSegment
  rückzug?: IAPIResultResponseSegment
  volatilität?: IAPIResultResponseSegment
  erleben?: IAPIResultResponseSegment
  intellekt?: IAPIResultResponseSegment
  politeness?: IAPIResultResponseSegment
  empathie?: IAPIResultResponseSegment
  dominanz?: IAPIResultResponseSegment
  enthusiasmus?: IAPIResultResponseSegment
}

export interface IAPIResultResponseSegment {
  facetten: IAPISubSegmentData[]
  mean: number
  percentageRank: number
  text: IAPITextData
}

export interface IAPIResultResponse {
  uuid?: string
  codeOption: 'u'|'t'|'c'|'a'
  extraversion: IAPIResultResponseSegment
  gewissenhaftigkeit: IAPIResultResponseSegment
  neurotizismus: IAPIResultResponseSegment
  offenheit: IAPIResultResponseSegment
  verträglichkeit: IAPIResultResponseSegment
}

export interface IAPIStatusResponse {
  status?: 'activated'|'inactive'|'locked'
}

export interface IAPIMissingAnswersResponse {
  missing: IAPIQuestionObject[]
}

export interface IAPIGivenAnswerObject {
  userId: string
  questionId: number
  answer: number
}

export interface IAPIGivenAnswersResponse {
  answers: IAPIGivenAnswerObject[]
}

export interface IAPITrainerResponse {
  id: number
  name: string
  email: string
  videoUrl: string
}

export interface IAPIQuestionObject {
  id: number
  nummerImTest: number
  upperCategory: string
  lowerCategory: string
  text: string
  isReverse: string
  updateDate: string
}
export type TAPIAllQuestionsResponse = IAPIQuestionObject[]

export interface IAPIPostAnswerResponse {
  status: 'answer updated' | 'answer stored'
  uniqueId: string
  questionId: number
  answerToQuestion: string
}

export class HTTPError extends Error {
  public statusCode?: number
  constructor(message: string, statusCode?: number) {
    super(message)
    this.statusCode = statusCode
  }
}

export class APIService {
  static BASE_URL = '/api/'

  constructor() {}

  private async _fetch(path: string, options: RequestInit = {}) {
    const response = await fetch(`${APIService.BASE_URL}${path}`, options)
    if (response.status !== 200) {
      let status, reason
      try {
        const json = await response.json()
        status = json.status
        reason = json.reason
      } catch (e) {}
      throw new HTTPError(status || reason || `Error code ${response.status}`, response.status)
    }
    return Utils.convertKeysToCamelCase(await response.json())
  }

  async fetchStatus(uniqueId: string): Promise<IAPIStatusResponse> {
    return this._fetch(`get_status/${uniqueId}`)
  }

  async fetchResult(uniqueId: string, trainerId?: number): Promise<IAPIResultResponse> {
    return this._fetch(`get_result/${uniqueId}` + (trainerId ? '?trainer_id=' + trainerId : ''))
  }

  async fetchQuestions(): Promise<TAPIAllQuestionsResponse> {
    return this._fetch(`get_all_questions/`)
  }

  async fetchMissingAnswers(uniqueId: string): Promise<IAPIMissingAnswersResponse> {
    return this._fetch(`missing_answer/${uniqueId}`)
  }

  async fetchGivenAnswers(uniqueId: string): Promise<IAPIGivenAnswersResponse> {
    return this._fetch(`given_answers/${uniqueId}`)
  }

  async fetchTrainer(hostname: string): Promise<IAPITrainerResponse> {
    return this._fetch(`get_trainer?hostname=${hostname}`)
  }

  async postAnswer(uniqueId: string, questionNumber: number, postValue: number): Promise<IAPIPostAnswerResponse> {
    return this._fetch(`post_answer/${questionNumber}/${uniqueId}`, {
      body: `answer=${postValue}`,
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      method: 'post',
    })
  }

  async fetchResultByUUID(uuid: string) {
    return this._fetch(`get_result_by_uuid/${uuid.toLowerCase()}`)
  }

  async registerAccount(code: string, otherData: Pick<IRegisterFormData, 'firstName' | 'lastName' | 'mail' | 'consent'>) {
    const formData = new FormData()
    const snakeCaseData = Utils.convertKeysToSnakeCase(otherData)
    for (let key in snakeCaseData) {
      formData.append(key, snakeCaseData[key])
    }
    return this._fetch(`activate/${code}`, {
      body: new URLSearchParams(Object.fromEntries(formData as any)),
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      method: 'post',
    })
  }
}
