import * as React from 'react'
import { FacebookIcon } from '../icons/social/FacebookIcon'
import { WhatsappIcon } from '../icons/social/WhatsappIcon'
import { MailIcon } from '../icons/social/MailIcon'
import { LinkIcon } from '../icons/social/LinkIcon'
import { LinkedInIcon } from '../icons/social/LinkedInIcon'
import { useParams } from 'react-router'

export const SocialShareButtons = () => {
  const { uniqueId } = useParams<{ uniqueId: string }>()
  const shareUrl = `https://test.unique-institut.com/${uniqueId}`
  const message = "Ich habe gerade einen Persönlichkeitstest nach modernstem wissenschaftlichen Standard gemacht und mein Ergebnis mit Millionen anderer Menschen verglichen.%0a%0aHier ist mein Ergebnis:%0a" + shareUrl + "%0a%0a"
    + "Wenn du auch mehr über deine Persönlichkeit erfahren und herausfinden willst, was dich einzigartig macht, dann findest du den Test hier: https://unique-institut.com"
  return (
    <ul className="socialShareButtons">
      <li className="socialShareButtons__item">
        <a href={`https://www.facebook.com/sharer.php?s=100&p[url]=${shareUrl}`} target="_blank">
          <FacebookIcon />
        </a>
      </li>
      <li className="socialShareButtons__item">
        <a href={`https://www.linkedin.com/sharing/share-offsite/?url=${shareUrl}`} target="_blank">
          <LinkedInIcon />
        </a>
      </li>
      <li className="socialShareButtons__item">
        <a href={`whatsapp://send?text=${message}`} target="_blank">
          <WhatsappIcon />
        </a>
      </li>
      <li className="socialShareButtons__item">
        <a href={`mailto:?subject=${encodeURIComponent("Ergebnis meines Persönlichkeitstests")}&body=${encodeURIComponent(message.replace(/%0a/ig, "\n"))}`}>
          <MailIcon />
        </a>
      </li>
      <li className="socialShareButtons__item">
        <a href={shareUrl} target="_blank">
          <LinkIcon />
        </a>
      </li>
    </ul>
  )
}
