import * as React from 'react'

export const LoadingIndicator = () => {
  return (
    <div className="loadingIndicator">
      <div className="loadingIndicator__ellipsis">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
    </div>
  )
}
