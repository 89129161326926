import * as React from 'react'
import { APIService } from '../lib/APIService'

export const APIContext = React.createContext(null)

export const Provider = APIContext.Provider

export function useAPI(mapStateToProps?: Function): APIService {
  const api = React.useContext(APIContext)

  if (typeof mapStateToProps !== 'undefined') {
    return mapStateToProps(api)
  }

  return api
}
