import * as React from 'react'
import * as _ from 'lodash'
import { SegmentPercentileDisplay } from './SegmentPercentileDisplay'

interface IProps {
  headline: string
  subline?: string
  text: string
  value: number
  description?: string
  strength: string
}

export const SegmentDetail = ({ headline, subline, text, value, description, strength }: IProps) => {
  const roundedValue = Math.round(value)
  return (
    <div className="segmentDetail">
      {subline && <h4 className="subline">{subline}</h4>}
      <h2 className="headline headline--huge headline--left segmentDetail__headline headline--upcase">{headline}</h2>

      <div className="segmentDetail__descriptionText">
        <p>{description && <>{description}</>}</p>
        <p className="segmentDetail__percentileText">{_.capitalize(strength)} - Du bist im {roundedValue}. Perzentil.</p>
        <p>Wenn du mit 100 Leuten in einem Raum wärst, dann wärst du in {headline} höher als {roundedValue - 1} von ihnen und niedriger als {100 - roundedValue} von ihnen.</p>
      </div>

      <SegmentPercentileDisplay value={Math.round(value)} label="That's you!" />

      <p className="text" dangerouslySetInnerHTML={{ __html: text }} />
    </div>
  )
}
