import * as React from 'react'
import { SVGProps } from 'react'

export const LinkIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" {...props} viewBox="0 0 150 150">
    <g fill="none" fillRule="evenodd">
      <circle fill="#B3B3B3" cx={75} cy={75} r={75} />
      <path
        d="m70.138 92.62-7.944 8.074a8.182 8.182 0 0 1-11.441 0l-2.59-2.63c-3.157-3.211-3.157-8.418 0-11.63l16.835-17.116a8 8 0 0 1 11.441 0l2.592 2.633a2.876 2.876 0 0 0 4.116 0 2.994 2.994 0 0 0 0-4.185l-2.592-2.632a13.744 13.744 0 0 0-19.652 0L44.069 82.25a14.248 14.248 0 0 0-4.07 9.989c0 3.747 1.464 7.34 4.07 9.99l2.589 2.629a13.773 13.773 0 0 0 9.826 4.141c3.686 0 7.22-1.49 9.826-4.141l7.94-8.071a2.985 2.985 0 0 0 .753-2.851 2.928 2.928 0 0 0-2.052-2.088 2.87 2.87 0 0 0-2.804.763l-.009.009Z"
        fill="#FFF"
        fillRule="nonzero"
      />
      <path
        d="m105.889 45.756-2.622-2.633c-5.505-5.498-14.398-5.498-19.902 0l-8.54 8.562a2.961 2.961 0 0 0 .036 4.138 2.93 2.93 0 0 0 4.12.036l8.526-8.561a8.177 8.177 0 0 1 11.585 0l2.626 2.636a8.243 8.243 0 0 1 2.401 5.819 8.243 8.243 0 0 1-2.401 5.819l-17.05 17.123a8.123 8.123 0 0 1-5.795 2.412 8.114 8.114 0 0 1-5.788-2.41 2.932 2.932 0 0 0-2.874-.842 2.946 2.946 0 0 0-2.119 2.124 2.96 2.96 0 0 0 .833 2.887A13.937 13.937 0 0 0 78.873 87a13.94 13.94 0 0 0 9.95-4.133l17.051-17.123A14.16 14.16 0 0 0 110 55.748a14.16 14.16 0 0 0-4.125-9.995l.015.003Z"
        fill="#FFF"
        fillRule="nonzero"
      />
    </g>
  </svg>
)
