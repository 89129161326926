import * as React from "react"
import { SVGProps } from "react"

export const ExtraversionIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 17.05 24.33" {...props}>
    <defs>
      <style>{".cls-1{fill:currentColor}"}</style>
    </defs>
    <g id="Layer_2" data-name="Layer 2">
      <g id="Ebene_1" data-name="Ebene 1">
        <path
          className="cls-1"
          d="M13.28 9.45v8.94c0 1-.46 1.48-1.36 1.46a1.18 1.18 0 0 1-1.27-1.34v-1.76a1.07 1.07 0 0 0-.05-.54c-.09-.16-.38-.36-.45-.32a.87.87 0 0 0-.44.53 11.83 11.83 0 0 0 0 1.46v5.05c0 1-.45 1.41-1.34 1.4s-1.27-.47-1.27-1.41V9.75a1.52 1.52 0 0 0-.52-1.22c-2-1.91-4-3.84-6-5.77C-.13 2.13-.17 1.51.39.94S1.51.42 2.17 1c1.45 1.38 2.92 2.72 4.35 4.09a1.75 1.75 0 0 0 1.31.51h6a1 1 0 0 1 1.17.79c.64 2.15 1.32 4.28 2 6.43a1.1 1.1 0 0 1-.7 1.56 1.12 1.12 0 0 1-1.56-.84l-1.33-4.11h-.13Z"
        />
        <path
          className="cls-1"
          d="M12.76 2.64A2.62 2.62 0 1 1 10.15 0a2.62 2.62 0 0 1 2.61 2.64ZM16.26 16.56c.36.64.26 1.15-.21 1.41v.05s-1.66.84-3.31 1.72a1 1 0 0 1-1.52-.43 1 1 0 0 1 .45-1.51l3-1.64a1 1 0 0 1 1.53.4Z"
        />
      </g>
    </g>
  </svg>
)
