import * as React from 'react'
import { ArrowIcon } from '../icons/ArrowIcon'
import classnames from 'classnames'

interface IProps {
  onPreviousClick?: () => any
  onNextClick?: () => any
  previousAvailable?: boolean
  nextAvailable?: boolean
  className?: string
}

export const NavigationButtons = ({ onPreviousClick, onNextClick, previousAvailable, nextAvailable, className }: IProps) => {
  return (
    <div className={classnames("navigationButtons", className)}>
      <div
        className={classnames('navigationButton navigationButton__left', { 'is-disabled': !previousAvailable })}
        onClick={onPreviousClick}
      >
        <div className="navigationButton__arrow">
          <ArrowIcon />
        </div>
        <div className="navigationButton__label">Zurück</div>
      </div>

      <a href="#top">
        <div className="navigationButton navigationButton--up">
          <div className="navigationButton__arrow">
            <ArrowIcon />
          </div>
          <div className="navigationButton__label">Hoch</div>
        </div>
      </a>
      <div
        className={classnames('navigationButton navigationButton__right', { 'is-disabled': !nextAvailable })}
        onClick={onNextClick}
      >
        <div className="navigationButton__label">Weiter</div>
        <div className="navigationButton__arrow navigationButton__arrow--flipped">
          <ArrowIcon />
        </div>
      </div>
    </div>
  )
}
