import * as React from "react"
import { SVGProps } from "react"

export const LinkedInIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" {...props} viewBox="0 0 150 150">
    <g fill="none" fillRule="evenodd">
      <g>
        <circle fill="#B3B3B3" cx={75} cy={75} r={75} />
        <g transform="translate(39 37)" fill="#FFF" fillRule="nonzero">
          <rect y={25} width={18} height={51} rx={0.5} />
          <circle cx={9} cy={9} r={9} />
          <path d="M59.346 76h10.846c.999 0 1.808-.818 1.808-1.827V43.475c0-13.777-7.7-20.465-18.475-20.465a15.136 15.136 0 0 0-11.46 4.641 1.472 1.472 0 0 1-1.642.423 1.498 1.498 0 0 1-.961-1.41c0-1.008-.81-1.826-1.808-1.826H26.808c-.999 0-1.808.818-1.808 1.827v47.508c0 1.009.81 1.827 1.808 1.827h10.846c.998 0 1.808-.818 1.808-1.827V46.764c0-5.045 4.046-9.136 9.038-9.136s9.038 4.09 9.038 9.136v27.409c0 1.009.81 1.827 1.808 1.827Z" />
        </g>
      </g>
    </g>
  </svg>
)
