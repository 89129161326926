import * as React from 'react'
import { PersonIcon } from '../../icons/PersonIcon'

interface IProps {
  progress: number
}

export const QuestionnaireProgress = ({ progress }: IProps) => {
  return (
    <div className="questionnaireProgress">
      <div className="questionnaireProgress__icon" style={{ left: `${progress}%` }}>
        <PersonIcon />
      </div>

      <div className="questionnaireProgress__label" style={{ left: `${progress}%` }}>
        {progress}%
      </div>
    </div>
  )
}
