import * as React from 'react'
import { SVGProps } from 'react'

export const AddIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" {...props}>
    <g fillRule="nonzero" fill="none">
      <path
        d="M.5 12c0 6.351 5.149 11.5 11.5 11.5S23.5 18.351 23.5 12 18.351.5 12 .5C5.652.507.507 5.652.5 12Z"
        style={{ fill: 'var(--chartAddButtonBackgroundColor)' }}
      />
      <path
        d="M5.5 11.5a1 1 0 0 1 1-1h3.75a.25.25 0 0 0 .25-.25V6.5a1 1 0 0 1 1-1h1a1 1 0 0 1 1 1v3.75c0 .138.112.25.25.25h3.75a1 1 0 0 1 1 1v1a1 1 0 0 1-1 1h-3.75a.25.25 0 0 0-.25.25v3.75a1 1 0 0 1-1 1h-1a1 1 0 0 1-1-1v-3.75a.25.25 0 0 0-.25-.25H6.5a1 1 0 0 1-1-1v-1Z"
        style={{ fill: 'var(--chartAddButtonIconColor)' }}
      />
    </g>
  </svg>
)
