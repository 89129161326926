import * as React from "react"
import { SVGProps } from "react"

export const VertraeglichkeitIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 26.32 25.23" {...props}>
    <defs>
      <style>{".cls-1{fill:currentColor}"}</style>
    </defs>
    <g id="Layer_2" data-name="Layer 2">
      <g id="Ebene_1" data-name="Ebene 1">
        <path
          className="cls-1"
          d="M10.35 9.79v13.97a1.29 1.29 0 0 1-1.6 1.45c-.74-.11-1.11-.56-1.12-1.44V17.6a1.18 1.18 0 0 0 0-.5 5.21 5.21 0 0 0-.5-.66c-.15.21-.42.42-.44.64V23.69c0 1-.47 1.54-1.4 1.53s-1.32-.49-1.32-1.52V10.12a.56.56 0 0 0-.11-.38 4.14 4.14 0 0 0-.19.49c-.4 1.27-.79 2.54-1.19 3.8a1.17 1.17 0 0 1-1.62.88 1.17 1.17 0 0 1-.73-1.64c.69-2.21 1.4-4.42 2.07-6.65a1 1 0 0 1 1.15-.83h7.7a1 1 0 0 1 1.1.8c.67 2.22 1.38 4.43 2.07 6.65.26.87 0 1.44-.76 1.67a1.16 1.16 0 0 1-1.63-.91c-.4-1.27-.79-2.54-1.19-3.81a3.83 3.83 0 0 0-.17-.43h-.12ZM7.12 0a2.75 2.75 0 0 1 2.75 2.71 2.73 2.73 0 0 1-5.46 0A2.74 2.74 0 0 1 7.12 0Z"
        />
        <path
          className="cls-1"
          d="M22.4 9.79v13.97c0 1.06-.59 1.59-1.6 1.45-.74-.11-1.11-.56-1.12-1.44V17.6c0-.17.06-.37 0-.5a5.29 5.29 0 0 0-.51-.66c-.15.21-.42.42-.43.64-.06.82 0 1.65 0 2.47v4.14c0 1-.47 1.54-1.4 1.53S16 24.73 16 23.7V10.12a.56.56 0 0 0-.11-.38c-.06.16-.14.32-.19.49-.4 1.27-.79 2.54-1.19 3.8a1.17 1.17 0 0 1-1.62.88 1.17 1.17 0 0 1-.73-1.64c.69-2.21 1.4-4.42 2.07-6.65a1 1 0 0 1 1.15-.83h7.7a1 1 0 0 1 1.1.8c.67 2.22 1.38 4.43 2.07 6.65.27.87 0 1.44-.76 1.67a1.16 1.16 0 0 1-1.62-.91c-.4-1.27-.79-2.54-1.18-3.81-.05-.15-.12-.28-.18-.43h-.11ZM19.16 0a2.75 2.75 0 0 1 2.75 2.71 2.73 2.73 0 0 1-5.46 0A2.74 2.74 0 0 1 19.16 0Z"
        />
      </g>
    </g>
  </svg>
)
