import * as React from 'react'
import { SVGProps } from 'react'

export const WhatsappIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" {...props} viewBox="0 0 150 150">
    <g fill="none" fillRule="evenodd">
      <circle fill="#B3B3B3" cx={75} cy={75} r={75} />
      <path
        d="M75 33c-23.073 0-41.827 18.747-42 41.988a41.597 41.597 0 0 0 7.49 23.867l-4.725 14.102a1.772 1.772 0 0 0 .42 1.798c.465.474 1.152.65 1.785.459l14.7-4.69a41.74 41.74 0 0 0 42.527 1.264c13.354-7.377 21.697-21.459 21.803-36.8C116.827 51.748 98.074 33 75 33Zm24.943 58.63c-2.369 4.576-7.06 7.421-12.149 7.37a36.74 36.74 0 0 1-11.976-3.527c-8.456-3.932-15.625-10.248-20.653-18.196-6.63-8.921-6.943-17.278-.556-24.19a7.455 7.455 0 0 1 6.457-1.975 5.352 5.352 0 0 1 4.2 3.068l1.319 3.245c.972 2.362 2.013 4.901 2.187 5.324a3.934 3.934 0 0 1 0 3.526 18.115 18.115 0 0 1-3.471 4.831 43.611 43.611 0 0 0 6.213 7.582 31.353 31.353 0 0 0 9.477 5.748c.971-1.27 2.88-3.526 3.47-4.479a3.441 3.441 0 0 1 4.652-1.269c1.32.494 8.713 4.161 8.713 4.161a4.968 4.968 0 0 1 2.36 1.728 7.15 7.15 0 0 1-.243 7.052Z"
        fill="#FFF"
        fillRule="nonzero"
      />
    </g>
  </svg>
)
