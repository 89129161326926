import * as React from 'react'
import { useEffect, useRef, useState } from 'react'
import {useParams} from 'react-router'
import { useNavigate } from 'react-router-dom'
import {LoadingIndicator} from "../../shared/LoadingIndicator";
import useAsyncEffect from "use-async-effect";
import {useAPI} from "../../../hooks/useAPI";
import {QuestionnaireOption} from "./QuestionnaireOption";
import {QuestionnaireProgress} from "./QuestionnaireProgress";
import {NavigationButtons} from "../../shared/NavigationButtons";
import { IAPIQuestionObject, IAPIGivenAnswerObject } from '../../../lib/APIService'
import { useConfig } from '../../../hooks/useConfig'
import _ from 'lodash'

const QUESTIONNAIRE_ANSWERS = [
  'Stimmt völlig',
  'Eher ja',
  'Weder noch',
  'Eher nein',
  'Stimmt nicht'
]

export const QuestionnaireScreen = () => {
  const { trainer } = useConfig()
  const { uniqueId, questionNumber: questionNumberParam } = useParams<{ uniqueId: string, questionNumber?: string }>()
  const questionNumber = parseInt(questionNumberParam || '0', 10)
  const [questions, setQuestions] = useState<IAPIQuestionObject[]>([])
  const [answers, setAnswers] = useState<IAPIGivenAnswerObject[]>([])
  const [loading, setLoading] = useState(true)
  const [selectedOption, setSelectedOption] = useState(null)
  const missingAnswers = useRef([])
  const api = useAPI()
  const navigate = useNavigate()

  const updateSelectedOption = () => {
    let answer = answers.find(a => a.questionId == questionNumber)?.answer
    if (answer != null) {
      answer = answer / 25 + 1
    }
    setSelectedOption(answer || null)
  }

  useEffect(() => {
    updateSelectedOption()
  }, [questionNumber])

  useAsyncEffect(async () => {
    setLoading(true)

    const questions = await api.fetchQuestions()
    setQuestions(questions.sort((a, b) => a.nummerImTest - b.nummerImTest))

    const { answers } = await api.fetchGivenAnswers(uniqueId)
    setAnswers(answers)
    updateSelectedOption()

    const { missing } = await api.fetchMissingAnswers(uniqueId)
    missingAnswers.current = missing
    const firstMissingAnswer = missing.sort((a, b) => a - b)[0]

    if ((!questionNumber && firstMissingAnswer) || (questionNumber && firstMissingAnswer && questionNumber !== firstMissingAnswer)) {
      navigate(`/${uniqueId}/${firstMissingAnswer}`)
    } else if(!firstMissingAnswer) {
      navigate(`/${uniqueId}`)
    }

    setLoading(false)
  }, [uniqueId])

  const gotoNextQuestion = () => {
    if (missingAnswers.current.length > 0) {
      navigate(`/${uniqueId}/${missingAnswers.current[0]}`)
    } else {
      if (trainer) {
        navigate(`/${uniqueId}/final`)
      } else {
        navigate(`/${uniqueId}`)
      }
    }
  }

  const onNextClick = () => {
    if (selectedOption === null) return

    setSelectedOption(null)
    gotoNextQuestion()
  }
  const onPreviousClick = () => {
    setSelectedOption(null)
    navigate(`/${uniqueId}/${questionNumber - 1}`)
  }

  const onOptionSelect = async (value: number) => {
    let postValue = 100 / 4 * (value - 1)
    setSelectedOption(value)

    const existingAnswer = answers.find(a => a.questionId == questionNumber)
    if (existingAnswer) {
      existingAnswer.answer = postValue
    } else {
      answers.push({ userId: uniqueId, questionId: questionNumber, answer: postValue })
    }

    missingAnswers.current = _.without(missingAnswers.current, questionNumber)

    void api.postAnswer(uniqueId, questionNumber, postValue)
    setTimeout(() => {
      gotoNextQuestion()
    }, 300)
  }

  let content = <></>
  if (loading) {
    content =  <LoadingIndicator />
  } else {
    const question = questions[questionNumber - 1]
    const previousAvailable = questionNumber > 1
    const nextAvailable = questionNumber < questions.length && selectedOption !== null

    if (!question) {
      content = <h1 className="headline headline--error">Die Frage konnte nicht gefunden werden.</h1>
    } else {
      const options = []
      for (let i = 5; i > 0; i--) {
        options.push(<QuestionnaireOption label={QUESTIONNAIRE_ANSWERS[5 - i]} active={selectedOption === i} value={i} onClick={onOptionSelect.bind(null, i)} />)
      }

      content = <>
        <h1 className="headline questionnaireHeadline">{question.text}</h1>

        <ul className="questionnaireForm">
          {options}
        </ul>

        <QuestionnaireProgress progress={questionNumber - 1} />
        <NavigationButtons nextAvailable={nextAvailable} previousAvailable={previousAvailable} {...{ onPreviousClick, onNextClick}} />
      </>
    }
  }

  return <div className="questionnaire screen">{content}</div>
}
