import * as React from 'react'
import { SVGProps } from 'react'

export const FacebookIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" {...props} viewBox="0 0 150 150">
    <g fill="none" fillRule="evenodd">
      <circle fill="#B3B3B3" cx={75} cy={75} r={75} />
      <path
        d="M94.239 57.29c-.282-.39-.688-.617-1.115-.623H81.76v-5.17c0-1.027.181-2.2 1.537-2.2h9.042c.402.02.79-.182 1.055-.55.266-.336.416-.781.422-1.247V32.833c0-1.012-.675-1.833-1.507-1.833H79.26c-14.467 0-15.582 15.033-15.582 19.617v6.05h-7.535c-.832 0-1.507.82-1.507 1.833v14.667c0 1.012.675 1.833 1.507 1.833h7.535v42.167c0 1.012.674 1.833 1.507 1.833h15.07c.831 0 1.506-.82 1.506-1.833V75h10.097c.777.005 1.43-.71 1.507-1.65l1.266-14.667a2.092 2.092 0 0 0-.392-1.393Z"
        fill="#FFF"
        fillRule="nonzero"
      />
    </g>
  </svg>
)
