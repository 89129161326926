import * as React from 'react'
import { SVGProps } from 'react'

export const MailIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" {...props} viewBox="0 0 150 150">
    <g fill="none" fillRule="evenodd">
      <circle fill="#B3B3B3" cx={75} cy={75} r={75} />
      <path
        d="M110.75 41h-71.5C37.455 41 36 42.434 36 44.204v9.195c-.003 1.2.675 2.3 1.755 2.852l35.75 18.39a3.292 3.292 0 0 0 2.99 0l35.75-18.39A3.199 3.199 0 0 0 114 53.399v-9.195c0-1.77-1.455-3.204-3.25-3.204ZM108 51.116 75.5 68 43 51.116V47h65v4.116Z"
        fill="#FFF"
        fillRule="nonzero"
      />
      <path
        d="M112.44 63.464a3.238 3.238 0 0 0-3.25 0L75 81.316l-34.255-17.95a3.238 3.238 0 0 0-3.187.11A3.267 3.267 0 0 0 36 66.27v37.466A3.257 3.257 0 0 0 39.25 107h71.5a3.257 3.257 0 0 0 3.25-3.264V66.27a3.267 3.267 0 0 0-1.56-2.806ZM108 90 91 79.965 108 71v19ZM59 79.965 42 90V71l17 8.965ZM42 101v-2.739L65.302 84l7.703 4.141c.937.5 2.053.5 2.99 0L83.698 84 107 98.228v2.739L42 101Z"
        fill="#FFF"
        fillRule="nonzero"
      />
    </g>
  </svg>
)
