import * as React from 'react'
import { useEffect, useState } from 'react'
import { useParams } from 'react-router'
import { useNavigate } from 'react-router-dom'
import { LoadingIndicator } from '../../shared/LoadingIndicator'
import { useAPI } from '../../../hooks/useAPI'
import useAsyncEffect from 'use-async-effect'
import { IRegisterFormData, RegisterForm } from './RegisterForm'
import { SplashContent } from './SplashContent'
import { ResultScreen } from '../result/ResultScreen'
import { HTTPError } from '../../../lib/APIService'
import { useConfig } from '../../../hooks/useConfig'

export const SplashScreen = () => {
  const { uniqueId } = useParams<{ uniqueId: string, questionNumber?: string }>()
  const { trainer } = useConfig()
  const [loading, setLoading] = useState(true)
  const [status, setStatus] = useState('inactive')
  const [result, setResult] = useState(null)
  const api = useAPI()
  const navigate = useNavigate()

  const updateStatus = async (uniqueId) => {
    setLoading(true)
    try {
      const { status } = await api.fetchStatus(uniqueId)
      if (status === 'activated' || status === 'locked') {
        const { missing } = await api.fetchMissingAnswers(uniqueId)
        const firstMissingAnswer = missing.sort((a, b) => a - b)[0]
        if (firstMissingAnswer) {
          navigate(`/${uniqueId}/${firstMissingAnswer}`)
        } else {
          const result = await api.fetchResult(uniqueId, trainer?.id)
          setResult(result)
          setLoading(false)
        }
      } else {
        setStatus(status)
        setLoading(false)
      }
    } catch (e) {
      console.log(e)
      if (e instanceof HTTPError && e.statusCode === 403) {
        navigate(`/${uniqueId}/final`)
      } else if (e instanceof HTTPError && e.statusCode === 404) {
        alert('Dieser Code exitiert nicht!')
      } else {
        alert('Beim Abrufen des Ergebnisses ist ein Fehler aufgetreten.')
      }
      setLoading(false)
    }
  }

  useAsyncEffect(async () => {
    setLoading(true)
    setStatus('inactive')
    setResult(null)

    if (!uniqueId) {
      setLoading(false)
      return
    }
    await updateStatus(uniqueId)
  },[uniqueId])

  const handleFormSubmit = async (data: IRegisterFormData) => {
    setLoading(true)

    const { code, ...otherData } = data
    const targetPath = `/${code}`
    try {
      if (!otherData.trainer) delete otherData.trainer
      const { status } = await api.registerAccount(code, otherData)
      if (status === 'account activated') {
        if (location.pathname === targetPath) {
          await updateStatus(data.code)
        } else {
          navigate(targetPath)
        }
      } else {
        setLoading(false)
        alert(`Server hat mit unerwartetem Status geantwortet: ${status}`)
      }
    } catch (e) {
      setLoading(false)
      switch (e.message) {
        case 'code already taken':
          navigate(targetPath)
          await updateStatus(data.code)
          return
        case 'Error code 404':
          alert('Dieser Code existiert nicht.')
          return
      }
      alert(`Beim Senden des Formulars ist ein Fehler aufgetreten: ${e.message}`)
    }
  }

  const renderContent = () => {
    if (!result) {
      return <SplashContent code={uniqueId} onFormSubmit={handleFormSubmit} />
    } else {
      return <ResultScreen result={result} />
    }
  }

  let content = <></>
  if (loading) {
    content =  <LoadingIndicator />
  } else {
    content = renderContent()
  }

  return content
}
