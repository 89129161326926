import { createGlobalStyle, css } from 'styled-components'

const colorProps = [
  'headerGradientColorLeft',
  'headerGradientColorRight',
  'textColor',
  'highlightedTextColor',
  'footerBackgroundColor',
  'textInputBorderColor',
  'textInputTextColor',
  'textInputPlaceholderColor',
  'headlineColor',
  'questionnaireHeadlineColor',
  'answerButtonTextColor',
  'answerSelectionRectangleColor',
  'answerProgressBackgroundColor',
  'answerProgressColor',
  'buttonBackgroundColor',
  'buttonBackgroundHoverColor',
  'buttonTextColor',
  'buttonTextHoverColor',
  'copyCodeButtonBackgroundColor',
  'copyCodeButtonBackgroundHoverColor',
  'copyCodeButtonTextColor',
  'copyCodeButtonTextHoverColor',
  'chartOuterRingColor',
  'chartOuterRingTextColor',
  'chartInnerRingTextColor',
  'chartAddButtonBackgroundColor',
  'chartAddButtonIconColor',
  'chartTopCategoryTextColor',
  'barGradientColorLeft',
  'barGradientColorRight',
  'barIconColor',
  'navigationButtonColor',
  'codeInputTextColor',
  'percentileTextColor',
  'categoryButtonBackgroundColor',
  'categoryButtonTextColor',
  'categoryButtonActiveBackgroundColor',
  'categoryButtonActiveTextColor',
  'headerTextColor',
  'footerTextColor',
  'footerLogoColor',
  'footerCoopTextColor'
]

const arrayColorProps: [string, number][] = [
  ['chartInnerRingColors', 10],
  ['chartValueColors', 5],
  ['answerButtonColors', 5]
]

export const GlobalTrainerCSS = createGlobalStyle`
  :root {
    ${props => css`
      ${colorProps.map(colorProp => `
        --${colorProp}: ${props.trainer[colorProp]};
      `).join('')}
      ${arrayColorProps.map(([colorProp, count]) => {
        let singularColorProp = colorProp.replace(/s$/, '')
        return `
          ${new Array(count).fill('0').map((_, i) => `
            --${singularColorProp}${i + 1}: ${props.trainer[colorProp][i]};
          `).join('')}
        `
      })}
    `}
  }
`
