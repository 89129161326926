import * as React from 'react'
import { IRegisterFormData, RegisterForm } from './RegisterForm'
import { useConfig } from '../../../hooks/useConfig'
import { VimeoVideo } from '../../shared/VimeoVideo'

interface IProps {
  code?: string
  onFormSubmit?: (data: IRegisterFormData) => any
}

export const SplashContent = ({ code, onFormSubmit }: IProps) => {
  const { trainer } = useConfig()
  return (<div className="splash screen">
    <h1 className="headline headline--huge splash__headline">
      {trainer?.splashHeadline ? <div dangerouslySetInnerHTML={{ __html: trainer.splashHeadline }} /> : <>STARTE DIE ANALYSE<br />DEINER PERSÖNLICHKEIT</>}
    </h1>

    {trainer?.videoUrl && <VimeoVideo url={trainer.videoUrl} className="splash__video" />}

    {trainer?.splashText ? <div dangerouslySetInnerHTML={{ __html: trainer.splashText}} class="text text--centered paragraph" /> : <>
      <p className="text text--centered">Nimm dir ca. 15 Minuten Zeit. Dir werden gleich nacheinander 100 sehr kurze Statements angezeigt, die du auf einer Skala von 1 (stimmt nicht) bis 5 (stimmt völlig) bewerten sollst.</p>

      <p className="text text--centered">Denke nicht zu lange über deine Antwort nach, sondern folge einfach deiner ersten Intuition. Dann wird das Ergebnis umso genauer deine Persönlichkeit wiedergeben.</p>

      <p className="text text--centered">Deine aktuelle Stimmung kann die Ergebnisse beeinflussen. Achte daher darauf, dass du dich zum Zeitpunkt der Durchführung deines Assessments nicht krank fühlst oder allzusehr gestresst, hungrig oder müde bist.</p>

      <h4 className="splash__subline">Fülle die unteren Felder aus, damit wir dir anschließend dein Testergebnis zusenden können:</h4>
    </>}

    <RegisterForm onSubmit={onFormSubmit} initialCode={code} />

    {!trainer && <p className="text text--centered text--dark">
      Du hast noch keinen Test-Code?<br />
      <a href="https://unique-institut.com" target="_blank" className="link">Hier bekommst du sie.</a>
    </p>}
  </div>)
}
