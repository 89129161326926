import * as React from 'react'
import { CloseIcon } from '../../icons/CloseIcon'
import { useRef, useState } from 'react'
import { LoadingIndicator } from '../../shared/LoadingIndicator'
import { useAPI } from '../../../hooks/useAPI'
import { IAPIResultResponse } from '../../../lib/APIService'

interface IProps {
  onClose?: () => any
  onAdded?: (friendName: string, result: IAPIResultResponse) => any
}

export const AddOverlay = ({ onClose, onAdded }: IProps) => {
  const [loading, setLoading] = useState(false)
  const [friendName, setFriendName] = useState('')
  const [code, setCode] = useState('')
  const api = useAPI()

  const onFormSubmit = async (e) => {
    e.preventDefault()
    setLoading(true)

    try {
      const result = await api.fetchResultByUUID(code)
      onAdded?.(friendName || '?', result)
    } catch (e) {
      console.error(e)
      alert('Ergebnis konnte nicht geladen werden.')
    }
    setLoading(false)
  }

  return (
    <div className="overlay">
      <div className="addOverlay">
        <div className="addOverlay__closeButton" onClick={onClose}>
          <CloseIcon />
        </div>
        <h4 className="headline addOverlay__headline">Füge ein weiteres Ergebnis hinzu</h4>

        <p className="text text--centered">
          Gib den Namen und den Code der anderen Person ein, um dessen Ergebnis mit deinem zu
          vergleichen:
        </p>

        <form onSubmit={onFormSubmit} className="form">
          <input
            type="text"
            className="textInput"
            placeholder="Name der Person"
            onChange={(e) => setFriendName(e.target.value)}
            value={friendName}
          />
          <input
            type="text"
            className="textInput textInput--upcase"
            placeholder="Code Eingeben"
            maxLength={7}
            onChange={(e) => setCode(e.target.value)}
            value={code}
          />
          <button className="formButton addOverlay__button">Ergebnis anzeigen</button>
        </form>

        {loading && <LoadingIndicator />}
      </div>
    </div>
  )
}
