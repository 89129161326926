import * as React from 'react'
import { useConfig } from '../../../hooks/useConfig'

export const FinalScreen = () => {
  const { trainer } = useConfig()
  return <div className="final screen">
    <h1 className="headline headline--huge splash__headline" dangerouslySetInnerHTML={{ __html: trainer.doneHeadline }} />
    <div class="text text--centered text--larger" dangerouslySetInnerHTML={{ __html: trainer.doneText }} />
  </div>
}
