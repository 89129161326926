import * as _ from 'lodash'

export const Utils = {
  convertKeysToCamelCase(obj): any {
    if (_.isArray(obj)) {
      return obj.map((item) => {
        return Utils.convertKeysToCamelCase(item)
      })
    }
    if (_.isObject(obj)) {
      const newObj = {}
      Object.keys(obj).forEach((key) => {
        newObj[_.camelCase(key)] = Utils.convertKeysToCamelCase(obj[key])
      })

      return newObj
    }
    return obj
  },

  convertKeysToSnakeCase(obj): any {
    if (obj instanceof FormData) return obj

    if (_.isArray(obj)) {
      return obj.map((item) => {
        return Utils.convertKeysToSnakeCase(item)
      })
    }
    if (_.isObject(obj)) {
      const newObj = {}
      Object.keys(obj).forEach((key) => {
        newObj[_.snakeCase(key)] = Utils.convertKeysToSnakeCase(obj[key])
      })

      return newObj
    }
    return obj
  },
}
