import * as React from 'react'
import { useState } from 'react'
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'
import { QuestionnaireScreen } from './screens/questionnaire/QuestionnaireScreen'
import { APIService } from '../lib/APIService'
import { Provider as APIProvider } from '../hooks/useAPI'
import { Provider as ConfigProvider } from '../hooks/useConfig'
import { SplashScreen } from './screens/splash/SplashScreen'
import { LoadingIndicator } from './shared/LoadingIndicator'
import { Header } from './shared/Header'
import { Footer } from './shared/Footer'
import useAsyncEffect from 'use-async-effect'
import { GlobalTrainerCSS } from './shared/GlobalTrainerCSS'
import { FinalScreen } from './screens/splash/FinalScreen'

const apiService = new APIService()

export const MainComponent = () => {
  const [loading, setLoading] = useState(true)
  const [config, setConfig] = useState({})
  useAsyncEffect(async () => {
    const { hostname } = location
    try {
      const trainer = await apiService.fetchTrainer(hostname)
      setConfig({ trainer })
    } catch (e) {}
    setLoading(false)
  }, [])

  return (
    <ConfigProvider value={config}>
      <APIProvider value={apiService}>
        <div id="top"></div>
          {loading ? <LoadingIndicator/> :
            <>
              <Header />
              <div className="app">
                {config.trainer && <GlobalTrainerCSS trainer={config.trainer} />}
                <Router>
                  <Routes>
                    <Route path="/" element={<SplashScreen/>}/>
                    <Route path="/:uniqueId" element={<SplashScreen/>}/>
                    <Route path="/:uniqueId/final" element={<FinalScreen/>}/>
                    <Route path="/:uniqueId/:questionNumber" element={<QuestionnaireScreen/>}/>
                  </Routes>
                </Router>
              </div>
            </>
          }
        <Footer />
      </APIProvider>
    </ConfigProvider>
  )
}
