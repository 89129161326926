import * as React from "react"
import { SVGProps } from "react"

export const GewissenhaftigkeitIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 29.82 25.23" {...props}>
    <defs>
      <style>{".cls-1{fill:currentColor}"}</style>
    </defs>
    <g id="Layer_2" data-name="Layer 2">
      <g id="Ebene_1" data-name="Ebene 1">
        <path
          className="cls-1"
          d="M29.82 14.12a.65.65 0 0 1 0 .19 4.23 4.23 0 0 1-1.65 1.6c-1.28.78-1.42 1.93-1.31 3.34.11 1.62 0 3.25 0 4.87 0 .48.09 1.07-.63 1.08s-.65-.62-.65-1.11v-2.4a3.61 3.61 0 0 0-.13-.62h-.25a4.24 4.24 0 0 0-.11.7v2.62c0 .47-.13.83-.68.81s-.61-.37-.61-.81v-6.54a1.69 1.69 0 0 0-.47-1c-.81-.82-1.7-1.58-2.6-2.39h-.08a8 8 0 0 0 .56-.83 7 7 0 0 1 1 .6c.58.48 1.11 1 1.69 1.49a2.09 2.09 0 0 0 .93.34c-.87-.85-1-1.63-.4-2.27a1.28 1.28 0 0 1 1.88.07c.55.64.37 1.44-.42 2.24a3.32 3.32 0 0 0 .86-.36c.45-.37.9-.74 1.34-1.12.29-.24.67-.72 1-.8.18 0 .67 0 .71.28ZM8.92 14.12a6.47 6.47 0 0 1-.59 1 3.81 3.81 0 0 1-1 .88 3.17 3.17 0 0 0-1.45 3.32c.12 1.62 0 3.25 0 4.87 0 .48.1 1.07-.62 1.08s-.65-.62-.65-1.11v-2.4a4.9 4.9 0 0 0-.13-.62h-.17a4.24 4.24 0 0 0-.11.7v2.62c0 .47-.13.83-.69.81s-.6-.37-.6-.81v-6.54a1.69 1.69 0 0 0-.47-1C1.7 16.11 1 15.35.26 14.64A.57.57 0 0 1 0 14a.44.44 0 0 1 .55-.32c.29.11.48.41.71.6.58.48 1.11 1 1.69 1.49a2 2 0 0 0 .93.34c-.87-.85-1-1.63-.4-2.27a1.27 1.27 0 0 1 1.87.07c.56.64.37 1.44-.41 2.24a3.32 3.32 0 0 0 .86-.36c.83-.69 1.62-1.43 2.41-2.14v-.14a8 8 0 0 0 .66.64Z"
        />
        <path
          className="cls-1"
          d="M18 9.79v14a1.29 1.29 0 0 1-1.6 1.45c-.75-.11-1.12-.56-1.12-1.44v-6.17c0-.17.06-.37 0-.5a4.49 4.49 0 0 0-.51-.66c-.15.21-.42.42-.43.64-.05.82 0 1.65 0 2.47v4.14c0 1-.46 1.54-1.4 1.53s-1.32-.49-1.32-1.52V10.15a.53.53 0 0 0-.12-.38c-.06.16-.13.32-.19.49-.39 1.27-.78 2.54-1.19 3.8a1.16 1.16 0 0 1-1.61.88 1.17 1.17 0 0 1-.73-1.64c.69-2.21 1.4-4.42 2.06-6.65A1 1 0 0 1 11 5.79h7.69a1 1 0 0 1 1.11.8c.67 2.22 1.38 4.43 2.06 6.65.27.87 0 1.44-.75 1.67a1.15 1.15 0 0 1-1.64-.91c-.4-1.27-.79-2.54-1.19-3.81a3.93 3.93 0 0 0-.18-.43h-.11ZM14.76 0a2.75 2.75 0 0 1 2.75 2.71 2.73 2.73 0 0 1-5.46 0A2.74 2.74 0 0 1 14.76 0Z"
        />
      </g>
    </g>
  </svg>
)
