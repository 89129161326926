import * as React from "react"
import { SVGProps } from "react"

export const OffenheitIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 14.38 24.33" {...props}>
    <defs>
      <style>{".cls-1{fill:currentColor}"}</style>
    </defs>
    <g id="Layer_2" data-name="Layer 2">
      <g id="Ebene_1" data-name="Ebene 1">
        <path
          className="cls-1"
          d="M4.23 16.41v-6.39A2.49 2.49 0 0 0 4 9C2.71 6.55 1.44 4.16.19 1.76A1.08 1.08 0 0 1 .77.1a1 1 0 0 1 1.42.61C3.11 2.45 4 4.2 4.94 6a.85.85 0 0 0 .91.58h2.73A.79.79 0 0 0 9.4 6c.9-1.76 1.83-3.5 2.76-5.25.41-.77.95-1 1.61-.62s.79.91.37 1.69c-1.2 2.3-2.43 4.59-3.61 6.9a3.44 3.44 0 0 0-.36 1.44v12.72c0 1-.47 1.46-1.35 1.41a1.11 1.11 0 0 1-1.15-1.2v-6.32a3.37 3.37 0 0 0-.47-.53c-.15.17-.36.32-.42.51a3 3 0 0 0 0 .8v5.26c0 1-.39 1.47-1.27 1.47s-1.23-.45-1.23-1.44v-6.43Z"
        />
        <path
          className="cls-1"
          d="M9.68 3.67a2.49 2.49 0 0 1-5 0 2.45 2.45 0 0 1 2.47-2.5 2.44 2.44 0 0 1 2.53 2.5Z"
        />
      </g>
    </g>
  </svg>
)
