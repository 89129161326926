import * as React from 'react'
import { useRef, useState } from 'react'
import useAsyncEffect from 'use-async-effect'
import { useAPI } from '../../../hooks/useAPI'
import { RadarChart } from './RadarChart'
import { SocialShareButtons } from '../../shared/SocialShareButtons'
import { SegmentTexts } from './SegmentTexts'
import { NavigationButtons } from '../../shared/NavigationButtons'
import { useParams } from 'react-router'
import { AddOverlay } from './AddOverlay'
import { YourCode } from './YourCode'
import { CoachingAd } from './CoachingAd'
import { useConfig } from '../../../hooks/useConfig'

export type TValue = { name: string; values: number[] }
const VALUE_ORDER: [string, [string, string]][] = [
  ['extraversion', ['enthusiasmus', 'dominanz']],
  ['neurotizismus', ['ruckzug', 'volatilitat']],
  ['vertraglichkeit', ['empathie', 'politeness']],
  ['gewissenhaftigkeit', ['fleiss', 'ordnung']],
  ['offenheit', ['erleben', 'intellekt']],
]

export const ResultScreen = ({ result }) => {
  const { trainer } = useConfig()
  const { uniqueId } = useParams<{ uniqueId: string }>()
  const [values, setValues] = useState<TValue[]>([])
  const [addOverlayOpen, setAddOverlayOpen] = useState(false)
  const api = useAPI()

  const getValuesFromResult = (result) => {
    const values = []
    VALUE_ORDER.forEach(([segment, subsegments]) => {
      subsegments.forEach((subsegment) => {
        const segmentData = result[segment]
        if (!segmentData) return
        const subsegmentData = segmentData.facetten.find((subsegmentData) => !!subsegmentData[subsegment])
        if (!subsegmentData) return
        values.push(subsegmentData[subsegment].percentageRank)
      })
    })
    return values
  }

  useAsyncEffect(async () => {
    setValues([
      {
        name: 'Du',
        values: getValuesFromResult(result),
      },
    ])
  }, [result])

  const onAddClick = () => {
    if (values.length === 5) {
      alert('Es können maximal 5 Personen angezeigt werden.')
      return
    }
    setAddOverlayOpen(true)
  }

  const onResultAdded = (name, result) => {
    values.push({
      name,
      values: getValuesFromResult(result),
    })
    setAddOverlayOpen(false)
  }

  const segmentTextsRef = useRef(null)
  const onPreviousClick = () => {
    segmentTextsRef.current?.switchToPrevious()
  }
  const onNextClick = () => {
    segmentTextsRef.current?.switchToNext()
  }


  let content = (
    <>
      <h1 className="headline headline--error">{trainer?.resultHeadline ? <div dangerouslySetInnerHTML={{ __html: trainer?.resultHeadline }} /> : <>Das macht dich einzigartig:</>}</h1>

      {values.length && <RadarChart values={values} onAddClick={onAddClick} />}

      <p className="text result__text">
        Aus deinen Antworten wurden deine Zahlenwerte berechnet und den Profilen von ca. 5 Millionen anderer Menschen gegenübergestellt. Das Resultat siehst du oben in der Grafik. Unten findest du zu allen 5 Faktoren sowie den 10 Aspekten eine ausführliche Beschreibung.
      </p>

      <YourCode code={result.uniqueId} />

      {!trainer && <SocialShareButtons />}

      {result.codeOption === 't' && <CoachingAd />}

      <SegmentTexts {...{ result }} ref={segmentTextsRef} />

      <p className="text text--faded">
        Hinweis: Es gibt in dieser Persönlichkeitsanalyse weder richtige und falsche noch gute und schlechte Antworten. Für eine Gesellschaft ist es wichtig, dass ihre Mitglieder unterschiedliche Ausprägungen der Persönlichkeitsmerkmale abdecken. Aus deiner Selbsteinschätzung im Vergleich mit den Profilen von Millionen anderer Menschen wurde dein Ergebnis ermittelt. Solltest du das Gefühl haben, dass einzelne Faktoren oder Aspekte zu stark oder zu schwach ausgeprägt sind, bist du eventuell bei der Beantwortung der Fragen zu selbstkritisch zu dir gewesen. Ebenso können vorübergehende oder dauerhafte Veränderungen deiner Stimmungslage bei der Beantwortung der Fragen zu verzerrten Ergebnissen führen.
      </p>

      <NavigationButtons nextAvailable previousAvailable {...{ onPreviousClick, onNextClick }} className="navigationButtons--withPaddingTop" />

      {addOverlayOpen && <AddOverlay onClose={() => setAddOverlayOpen(false)} onAdded={onResultAdded} />}
    </>
  )

  return <div className="result screen">{content}</div>
}
