import * as React from 'react'
import useAsyncEffect from 'use-async-effect'
import { useState } from 'react'

interface IProps {
  url: string
  className?: string
}

export const VimeoVideo = ({ url, className }: IProps) => {
  const [embedHTML, setEmbedHTML] = useState('')
  useAsyncEffect(async () => {
    if (!url) return
    const response = await fetch('https://vimeo.com/api/oembed.json?url=' + url)
    const json = await response.json()

    setEmbedHTML(json.html)
  }, [url])
  return <div dangerouslySetInnerHTML={{ __html: embedHTML }} {...{ className }} />
}
