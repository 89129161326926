import * as React from 'react'
import { SVGProps } from 'react'

export const ArrowIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" {...props}>
    <path
      d="M21.016.531A2 2 0 0 0 19 .563L2.929 10.288a2 2 0 0 0 0 3.422L19 23.436a2 2 0 0 0 3.035-1.711V2.274A2 2 0 0 0 21.016.531Z"
      fill="currentColor"
    />
  </svg>
)
