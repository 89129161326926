import * as React from 'react'
import { SVGProps } from 'react'

export const PersonIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" {...props}>
    <path
      d="M15 6.5H9a3 3 0 0 0-3 3v5a1 1 0 0 0 2 0v-5a.936.936 0 0 1 .147-.5c.069-.109.279-.127.307.126L9 14.025v8.728a1 1 0 0 0 2 0v-5.5a.25.25 0 0 1 .25-.25h1.5a.25.25 0 0 1 .25.25v5.5a1 1 0 0 0 2 0v-8.719s.55-4.776.55-4.954a.165.165 0 0 1 .3-.093.935.935 0 0 1 .15.513v5a1 1 0 0 0 2 0v-5a3 3 0 0 0-3-3Z"
      fill="currentColor"
    />
    <circle cx={12} cy={2.75} r={2.5} fill="currentColor" />
  </svg>
)
