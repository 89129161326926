import * as React from 'react'
import { useRef, useState } from 'react'
import { CopyIcon } from '../../icons/CopyIcon'

interface IProps {
  code: string
}

export const YourCode = ({ code }: IProps) => {
  const textInputRef = useRef<HTMLInputElement>()
  const [copied, setCopied] = useState(false)

  const onCopyClick = () => {
    if (navigator.clipboard) {
      navigator.clipboard?.writeText(code)
    } else if (document.queryCommandSupported?.('copy')) {
      const el = textInputRef.current
      if (navigator.userAgent.match(/ipad|ipod|iphone/i)) {
        // save current contentEditable/readOnly status
        var editable = el.contentEditable
        var readOnly = el.readOnly

        // convert to editable with readonly to stop iOS keyboard opening
        el.contentEditable = true
        el.readOnly = true

        // create a selectable range
        var range = document.createRange()
        range.selectNodeContents(el)

        // select the range
        var selection = window.getSelection()
        selection.removeAllRanges()
        selection.addRange(range)
        el.setSelectionRange(0, 999999)

        // restore contentEditable/readOnly to original state
        el.contentEditable = editable
        el.readOnly = readOnly
      } else {
        el.select()
      }
      document.execCommand('copy')
    }
    setCopied(true)
  }

  return <div className="yourCode">
    <div className="yourCode__form">
      <div className="yourCode__label">Dein Code:</div>
      <div className="yourCode__formContent">
        <input type="text" className="textInput yourCode__input" value={code.toUpperCase()} ref={textInputRef} />
        <div className="yourCode__button" onClick={onCopyClick}>
          <CopyIcon />
        </div>
      </div>
    </div>
    {copied && <div className="yourCode__copyNotice">Kopiert!</div>}
  </div>
}
