import * as React from "react"
import { SVGProps } from "react"

export const NeurotizismusIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 23.14 24.33" {...props}>
    <defs>
      <style>{".cls-1{fill:currentColor}"}</style>
    </defs>
    <g id="Layer_2" data-name="Layer 2">
      <g id="Ebene_1" data-name="Ebene 1">
        <path
          className="cls-1"
          d="M20.47 8.5a4 4 0 0 0-5.85-5A5.62 5.62 0 0 0 3.77 5.62v.69a3.77 3.77 0 1 0 0 7.53h16.7a2.67 2.67 0 1 0 0-5.34ZM10.31 15.46l-1.45 4.4h2l-1.3 4.47 4.72-6.31h-2.44l.92-2.56h-2.45z"
        />
      </g>
    </g>
  </svg>
)
