import * as React from 'react'
import classnames from 'classnames'

interface IProps {
  onClick?: () => any
  active?: boolean
  label: string
  value: number
}

export const QuestionnaireOption = (props: IProps) => {
  return (
    <li className={classnames('questionnaireOption', { 'is-active': props.active })} onClick={props.onClick}>
      {props.label}
      <div className="questionnaireOption__right">
        <div className="questionnaireOption__checkbox" />
        <div className="questionnaireOption__value">{props.value}</div>
      </div>
    </li>
  )
}
